var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { Layout, Dependencies } from "~/core/decorator";
import { State } from "vuex-class";
import DataForm from "~/components/common/data-form.vue";
import DataBox from "~/components/common/data-box.vue";
import NumberRange from "~/components/common/number-range.vue";
import CaseAllExport from "~/components/case-manage/case-import/case-all-export.vue";
import { ExportAssistManagementService } from "~/services/dataimp-service/export-assist-management.service";
import { DepartmentService } from "~/services/manage-service/department.service";
import CaseExport from "~/components/case-manage/case-all-manage/case-export.vue";
import ContactExport from "~/components/case-manage/case-all-manage/contact-export.vue";
import { CaseCollectorService } from "~/services/business-service/case-collector.service";
var CaseInfoExport = /** @class */ (function (_super) {
    __extends(CaseInfoExport, _super);
    function CaseInfoExport() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.exportData = {};
        _this.batchNumberList = [];
        _this.loading = {
            state: false,
        };
        _this.searchModel = {
            type: "",
            principalId: "",
            batchNumber: "",
            leftAmt: {
                min: "",
                max: "",
            },
            cities: "",
            certificateNos: "",
            account: "",
            cardNo: "",
            payStatus: "",
            stage: "",
        };
        _this.dialog = {
            caseExport: false,
            baseCaseExport: false,
            contactExport: false,
        };
        _this.rules = {
            type: [
                {
                    required: true,
                    message: "请选择案件池",
                    trigger: "change",
                },
            ],
            principalId: [
                {
                    required: true,
                    message: "请选择委托方",
                    trigger: "change",
                },
            ],
        };
        return _this;
    }
    CaseInfoExport.prototype.handleResetForm = function () {
        this.searchModel.leftAmt = [];
    };
    /**
     * 案件导出
     */
    CaseInfoExport.prototype.caseExport = function () {
        if (!this.searchModel.type) {
            this.$message.warning("请选择案件池！");
        }
        else if (!this.searchModel.principalId) {
            this.$message.warning("请选择委托方！");
        }
        else {
            this.exportData = this.searchModel;
            this.dialog.baseCaseExport = true;
        }
    };
    CaseInfoExport.prototype.historyExport = function () {
        if (!this.searchModel.type) {
            this.$message.warning("请选择案件池！");
        }
        else if (!this.searchModel.principalId) {
            this.$message.warning("请选择委托方！");
        }
        else {
            this.exportData = this.searchModel;
            this.dialog.caseExport = true;
        }
    };
    CaseInfoExport.prototype.phoneExport = function () {
        if (!this.searchModel.type) {
            this.$message.warning("请选择案件池！");
        }
        else if (!this.searchModel.principalId) {
            this.$message.warning("请选择委托方！");
        }
        else {
            this.exportData = this.searchModel;
            this.dialog.contactExport = true;
        }
    };
    CaseInfoExport.prototype.searchBatchNumber = function (value) {
        var _this = this;
        if (value && value !== "") {
            this.searchModel.batchNumber = "";
            this.batchNumberList = [];
            if (!this.searchModel.type) {
                this.$message.warning("请选择案件池!");
            }
            else {
                this.caseCollectorService.getBatchNumber(this.searchModel.principalId, this.searchModel.type, this.loading).subscribe(function (data) {
                    var batchNumber = [];
                    data.map(function (e) {
                        batchNumber.push(e.batchNumber);
                    });
                    _this.batchNumberList = batchNumber;
                }, function (err) { });
            }
        }
    };
    /**
     * 重置输入表单
     */
    CaseInfoExport.prototype.resetForm = function () {
        var dataForm = this.$refs["data-form"];
        dataForm.resetFields();
        this.handleResetForm();
    };
    CaseInfoExport.prototype.batchType = function () {
        this.searchModel.principalId = "";
        this.searchModel.batchNumber = "";
    };
    __decorate([
        Dependencies(ExportAssistManagementService)
    ], CaseInfoExport.prototype, "exportAssistManagementService", void 0);
    __decorate([
        Dependencies(DepartmentService)
    ], CaseInfoExport.prototype, "departmentService", void 0);
    __decorate([
        Dependencies(CaseCollectorService)
    ], CaseInfoExport.prototype, "caseCollectorService", void 0);
    __decorate([
        State
    ], CaseInfoExport.prototype, "principalList", void 0);
    CaseInfoExport = __decorate([
        Layout("workspace"),
        Component({
            components: {
                DataForm: DataForm,
                DataBox: DataBox,
                NumberRange: NumberRange,
                CaseAllExport: CaseAllExport,
                CaseExport: CaseExport,
                ContactExport: ContactExport,
            },
        })
    ], CaseInfoExport);
    return CaseInfoExport;
}(Vue));
export default CaseInfoExport;
