var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { Dependencies } from '~/core/decorator';
import { Emit, Prop } from 'vue-property-decorator';
import { DataExportService } from '~/services/dataimp-service/data-export.service';
import { ExportTemplateService } from '~/services/dataimp-service/export-template.service';
var ContactExport = /** @class */ (function (_super) {
    __extends(ContactExport, _super);
    function ContactExport() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.model = {
            templateId: '',
        };
        _this.loading = {
            state: false
        };
        _this.AllConfigs = [];
        return _this;
    }
    ContactExport.prototype.created = function () {
    };
    ContactExport.prototype.refresh = function () {
        var _this = this;
        this.exportTemplateService.getExportTemplateByPrincipal(this.exportData.principalId, 'CONTACT', this.loading)
            .subscribe(function (data) { return (_this.AllConfigs = data); }, function (err) { });
    };
    ContactExport.prototype.submit = function () {
        var _this = this;
        if (this.model.templateId === '') {
            this.$message('请选择导出模板');
            return;
        }
        var model = {
            type: this.exportData.type,
            principalId: this.exportData.principalId,
            batchNumber: this.exportData.batchNumber,
            minLeftAmt: this.exportData.leftAmt.min,
            maxLeftAmt: this.exportData.leftAmt.max,
            cities: this.exportData.cities,
            certificateNos: this.exportData.certificateNos,
            account: this.exportData.account,
            cardNo: this.exportData.cardNo,
            payStatus: this.exportData.payStatus,
            stage: this.exportData.stage,
            templateId: this.model.templateId
        };
        this.loading.state = true;
        this.dataExportService.exportContact(model, this.loading).subscribe(function (data) {
            _this.$message.success('正在导出,请关注任务中心!');
            _this.close();
        }, function (err) { });
    };
    ContactExport.prototype.close = function () {
        this.reset();
    };
    ContactExport.prototype.success = function () {
        this.close();
    };
    ContactExport.prototype.reset = function () {
        this.model.templateId = '';
    };
    __decorate([
        Dependencies(ExportTemplateService)
    ], ContactExport.prototype, "exportTemplateService", void 0);
    __decorate([
        Dependencies(DataExportService)
    ], ContactExport.prototype, "dataExportService", void 0);
    __decorate([
        Prop({
            required: true
        })
    ], ContactExport.prototype, "exportData", void 0);
    __decorate([
        Emit()
    ], ContactExport.prototype, "close", null);
    __decorate([
        Emit()
    ], ContactExport.prototype, "success", null);
    ContactExport = __decorate([
        Component({
            components: {},
        })
    ], ContactExport);
    return ContactExport;
}(Vue));
export default ContactExport;
